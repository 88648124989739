/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Container, Content, ImageContainer, ImageWrapper } from "./styles";
import teste from "../../assets/speechEugenie1.jpg";
import teste1 from "../../assets/speechEugenie2.jpg";
import speechjose1 from "../../assets/speechJose1.jpg";
import speechjose2 from "../../assets/speechjose2.jpg";
import speechbruna1 from "../../assets/speechbruna1.jpg";
import speechbruna2 from "../../assets/speechbruna2.jpg";
import speechbruno1 from "../../assets/speechbruno1.jpg";
import speechbruno2 from "../../assets/speechbruno2.jpg";
import speechelise1 from "../../assets/speechelisa1.jpg";
import speechelisa2 from "../../assets/speechelisa2.jpg";
import speecherica1 from "../../assets/speecherica1.jpg";
import speecherica2 from "../../assets/speecherica2.jpg";
import speechflavia1 from "../../assets/speechflavia1.jpg";
import speechflavia2 from "../../assets/speechflavia2.jpg";
import speechfoued1 from "../../assets/speechfoued1.jpg";
import speechfoued2 from "../../assets/speechfoued2.jpg";
import speechligia1 from "../../assets/speechligia1.jpg";
import speechligia2 from "../../assets/speechligia2.jpg";
import speechmarcos1 from "../../assets/speechmarcos1.jpg";
import speechmarcos2 from "../../assets/speechmarcos2.jpg";
import speecholiver1 from "../../assets/speecholiver1.jpg";
import speecholiver2 from "../../assets/speecholiver2.jpg";
import speechvasco1 from "../../assets/speechvasco1.jpg";
import speechvasco2 from "../../assets/speechvasco2.jpg";
import speechvinicius1 from "../../assets/speechvinicius1.jpg";
import speechvinicius2 from "../../assets/speechvinicius2.jpg";
import speechJaime from "../../assets/speechJaime.jpg";
import speechJaime2 from "../../assets/speechjaime2.jpg";
import apeechlucasmari from "../../assets/apeechlucasmari.jpeg";
import speechlucasmari from "../../assets/speechlucasmari.jpeg";
import josianesilva from "../../assets/josianesilva.jpeg";
import josianesilva2 from "../../assets/josianesilva2.jpeg";
import leonardotachibana from "../../assets/leonardotachibana.jpeg";
import leonardotachibana2 from "../../assets/leonardotachibana2.jpeg";
import evandroabreu from "../../assets/evandroabreu.jpeg";
import evandroabreu2 from "../../assets/evandroabreu2.jpeg";
import richardcosta from "../../assets/richardcosta.jpeg";
import richardcosta2 from "../../assets/richardcosta2.jpeg";
import lucianolopes from "../../assets/lucianolopes.jpeg";
import lucianolopes2 from "../../assets/lucianolopes2.jpeg";
import rodrigodias1 from "../../assets/rodrigodias1.jpeg";
import rodrigodias2 from "../../assets/rodrigodias2.jpeg";
import mauricio from "../../assets/mauricio.jpeg";
import mauricio2 from "../../assets/maruricio2.jpeg";


const imagePairs = [
  { img1: teste, img2: teste1 },
  { img1: speechjose1, img2: speechjose2 },
  { img1: speechbruna1, img2: speechbruna2 },
  { img1: speechbruno1, img2: speechbruno2 },
  { img1: speechelise1, img2: speechelisa2 },
  { img1: speecherica1, img2: speecherica2 },
  { img1: speechflavia1, img2: speechflavia2 },
  { img1: speechfoued1, img2: speechfoued2 },
  { img1: speechligia1, img2: speechligia2 },
  { img1: speechmarcos1, img2: speechmarcos2 },
  { img1: speecholiver1, img2: speecholiver2 },
  { img1: speechvasco1, img2: speechvasco2 },
  { img1: speechvinicius1, img2: speechvinicius2 },
  { img1: speechJaime, img2: speechJaime2 },
  { img1: apeechlucasmari, img2: speechlucasmari },
  { img1: josianesilva2, img2: josianesilva },
  { img1: leonardotachibana, img2: leonardotachibana2 },
  { img1: evandroabreu, img2: evandroabreu2 },
  { img1: richardcosta, img2: richardcosta2 },
  { img1: lucianolopes, img2: lucianolopes2 },
  { img1: rodrigodias1, img2: rodrigodias2 },
  { img1: mauricio, img2: mauricio2 },
];

function Speech() {
  return (
    <>
      <Container>
        <Header />
        <Content>
          {imagePairs.map((pair, index) => (
            <ImageContainer key={index}>
              <ImageWrapper>
                <img src={pair.img1} alt={` ${index * 2 + 1}`} />
                <img src={pair.img2} alt={` ${index * 2 + 2}`} />
              </ImageWrapper>
            </ImageContainer>
          ))}
        </Content>
      </Container>
      <Footer />
    </>
  );
}

export default Speech;
